import React from 'react';
import './FullPageLayout.css'; // CSS file for styling


const FullPageLayout = ({ children, className , targetRef, background , style}) => {
  const classes = ['full-page-layout'];

  if (className) {
    classes.push(className);
  }
  // const style = {

  // }
  if (background) {
    style["backgroundImage"] = `url(${background})`
  }
  return (
    <div ref={targetRef} className={classes.join(' ')} style={style}>
      {children}
    </div>
  );
};

export function FullWidthLayout({ children, className , targetRef, background ,backgroundColor, style={}}) {
  const classes = ['full-width-layout'];
  // 

  if (className) {
    classes.push(className);
  }
  // const style = {

  // }
  // style["width"] = "100%"
  // style["display"] = "flex"
  // if (background) {
  //   style["backgroundImage"] = `url(${background})`
  // }
  // style["backgroundColor"] = "#ff00ff"
  // if (backgroundColor) {
  //   style["backgroundColor"] = background
  // }
  return (
    <div ref={targetRef} className={classes.join(' ')} style={style}>
      {children}
    </div>
  );
}


export default FullPageLayout;