
export const SilkChamber = [
  "/images/SilkChamber/prerona_29.webp",
  "/images/SilkChamber/prerona_25.webp",
  "/images/SilkChamber/prerona_26.webp",
  "/images/SilkChamber/prerona_27.webp",
  "/images/SilkChamber/prerona_30.webp",
]


export const ShreeSilk = [
  
  "/images/ShreeSilk/prerona_16.webp",
  "/images/ShreeSilk/prerona_4.webp",
  "/images/ShreeSilk/prerona_5.webp",
  "/images/ShreeSilk/prerona_6.webp",
  "/images/ShreeSilk/prerona_17.webp",
]

export const IraThreads = [
  "/images/IraThreads/prerona_20.webp",
  "/images/IraThreads/prerona_18.webp",
  "/images/IraThreads/prerona_19.webp",
  "/images/IraThreads/prerona_21.webp",
]
export const PriyankoBiswas = [
  "/images/PriyankoBiswas/prerona_31.webp",
  "/images/PriyankoBiswas/prerona_32.webp",
  "/images/PriyankoBiswas/prerona_33.webp",
  "/images/PriyankoBiswas/prerona_34.webp",
]

export const AnkurRajbonshi = [
  "/images/AnkurRajbonshi/prerona_8.webp",
  "/images/AnkurRajbonshi/prerona_7.webp",
  "/images/AnkurRajbonshi/prerona_9.webp",
  "/images/AnkurRajbonshi/prerona_13.webp",
]

export const Runway = [
  "/images/Runaway/prerona_1.webp",
  "/images/Runaway/prerona_2.webp",
  "/images/Runaway/prerona_10.webp",
  "/images/Runaway/prerona_11.webp",
  "/images/Runaway/prerona_22.webp",
  "/images/Runaway/prerona_23.webp",
  "/images/Runaway/prerona_24.webp",
]

