import React from "react";
import NavBar from "../../components/navbar/NavBar";
import { MultiClickImage} from "../../components/image/Image";
import './Home.css'
import { FullWidthLayout } from "../../components/page/FullPageLayout";
import TitleFont from "../../components/Text/TitleFont";
import { WaveGap } from "../../components/gapFillers/Gap";
import {SilkChamber, ShreeSilk , IraThreads, PriyankoBiswas, AnkurRajbonshi, Runway} from "../../Config";
import Footer from "../../components/footer/Footer";

export default function Home() {
  return (
    <>
      <NavBar/>
      <div className="content-main">
        {/* Flex content, full width, padding equal to navbar in landscape, equal to half in portrait */} 
        <FullWidthLayout className="flex-start item" bgColor="#000000">
            <MultiClickImage className="slideInFromBottomAnimation" imageSrc={SilkChamber}/>
        </FullWidthLayout>
        <div className="full-width flex-start flex-row" bgColor="#ffffff"> 
        <TitleFont className="flex-start">Silk Chamber</TitleFont>
        <WaveGap/>
        </div>

        <FullWidthLayout className="flex-end item" bgColor="">
          <MultiClickImage className="slideInFromBottomAnimation" imageSrc={ShreeSilk}/>
        </FullWidthLayout>
        <div className="full-width flex-end"> 
          <TitleFont>Shree Silk</TitleFont>  
        <WaveGap/>
        </div>
        
        <FullWidthLayout className="flex-center item" bgColor="">
          <MultiClickImage className="slideInFromBottomAnimation" imageSrc={IraThreads} />
        </FullWidthLayout>
        <div className="full-width flex-center"> 
          <TitleFont>Ira Threads</TitleFont>  
        <WaveGap/>
        </div>

        <FullWidthLayout className="flex-end item" bgColor="">
          <MultiClickImage imageSrc={PriyankoBiswas}/>
        </FullWidthLayout>
        <div className="full-width flex-end"> 
          <TitleFont>Priyanko Biswas</TitleFont>  
        <WaveGap/>

        </div>

        <FullWidthLayout className="flex-start item">
          <MultiClickImage imageSrc={AnkurRajbonshi}/>
        </FullWidthLayout>
        <div className="full-width flex-end"> 
          <TitleFont>Ankur Rajbonshi</TitleFont>  
        <WaveGap/>

        </div>

        <FullWidthLayout className="flex-center item">
          <MultiClickImage imageSrc={Runway}/>
        </FullWidthLayout>
        <div className="full-width flex-end"> 
          <TitleFont>Runaway Shoots</TitleFont>  
        <WaveGap/>
        </div>

        <Footer/>
      </div>

    </>
  )
}
