import React, { useState } from "react";
import Fade from 'react-reveal/Fade';

import './Image.css'

export default function ImageWrapper({imageSrc}) {
  return (
    <img src={imageSrc} alt="Modelling" className="image"></img>
  )
}

export function ClickableImage({imageSrc, link, portraitHide, className}) {
  
  var classes = "image "
  if (className) {
    classes += " " + className
  }

  if (portraitHide) {
    if (window.innerWidth < 768) {
      return (
        <></>
      )
    }
  }

  return (
    <a href={link}><img src={imageSrc} alt="Modelling" className={classes}></img></a>
  )
  
}

export function MultiClickImage({imageSrc, className}) {
  
  const [index, setIndex] = useState(0);
  const [fadeIn, setFadeIn] = React.useState(0)
  var classes = "image "
  if (className) {
    classes += " " + className
  }

  function iteratre() {
    setFadeIn(1)
    setIndex(index + 1)
    if (index >= imageSrc.length - 1) {
      setIndex(0)
    }
    console.log(index, imageSrc.length)
    console.log("Scroll Position",window.scrollY)
  } 

  return (
    <Fade bottom><img src={imageSrc[index]} 
      alt="Modelling"
      className={classes} 
      onClick={iteratre} 
      onAnimationEnd={() => setFadeIn(0)} 
      fadeIn={fadeIn}></img>
    </Fade>
  )
  
}


