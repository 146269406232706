import React from "react";

export default function GradientGap({height="48px", topColor, bottomColor}) {

  const style = {
    "height": height,
    "width" : "100%",
    "background" : "linear-gradient(90deg, "+ topColor + " 0%, " + bottomColor +" 100%);"
  }

  return (
    <div className="gradient_29_16" style={style}>

    </div>
  )
}

export function WaveGap(){
  return (
    <div>
      <svg viewBox="0 0 1447.8437823800946 52.86725256660611" >
  <g stroke-linecap="round"><g transform="translate(726.716957670399 -683.412439409507) rotate(89.99999999999994 -3.375029776583915 712.2340147278558)"><path d="M-1.92 -1.69 C0.27 28.08, 13.25 110.18, 12.42 179.15 C11.59 248.11, -6.69 330.48, -6.91 412.09 C-7.13 493.7, 13.26 589.84, 11.11 668.8 C8.96 747.76, -19.54 810.21, -19.81 885.84 C-20.08 961.46, 7.61 1053.39, 9.48 1122.57 C11.36 1191.75, -7.02 1250.43, -8.57 1300.91 C-10.13 1351.39, -0.85 1404.79, 0.17 1425.45 M2.23 3.57 C4.02 33.78, 11.36 114.29, 9.6 182.85 C7.83 251.4, -8.49 333.47, -8.36 414.89 C-8.23 496.3, 11.66 592.67, 10.39 671.34 C9.11 750, -16.44 811.95, -16.01 886.87 C-15.57 961.79, 11.53 1052.16, 12.98 1120.87 C14.43 1189.58, -4.79 1248.27, -7.31 1299.15 C-9.83 1350.03, -3.53 1405.55, -2.15 1426.16" stroke="#1e1e1e" strokeWidth="1" fill="none"></path></g></g><mask></mask></svg>
    </div>
  )
}