import React from "react";
import "./NavBar.css"
export default function NavBar() {
  return (
    <>
      <div className="navbar-container"> 
      {/* Container div. Must be of fixed height (56px), full width */}
        <p className="navbar-text">Prerona Borkotoky</p>
        {/* <p className="navbar-text-right">Gallery</p> */}
      </div>
    </>
  )
}