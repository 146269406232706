import React from "react";
import "./Footer.css"
import {FaInstagram, FaLinkedin} from "react-icons/fa6"

export default function Footer() {
  return (
    <>
      <div className="footer-container"> 
      {/* Container div. Must be of fixed height (56px), full width */}
        <p className="footer-text">&copy; Prerona Borkotoky</p>

        <div className="navbar-text-right">
          <a href="https://www.instagram.com/prerona_postrona/"><FaInstagram/></a>
          <a href="https://www.linkedin.com/in/prerona-borkotoky/"><FaLinkedin/></a>
        </div>
      </div>
    </>
  )
}