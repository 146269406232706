import React from 'react';
import './Text.css'; // CSS file for styling
import BaseComponent from '../base/Base';


export function H2({children, style, className}) {
  return (
    <BaseComponent className={className} primaryClassName="text-h2">
      {children}
    </BaseComponent>
  )
}

export function H4({children, style, className}) {
  return (
    <BaseComponent className={className} primaryClassName="text-h4">
      {children}
    </BaseComponent>
  )
}

export function TextNormal({children, style, className}) {
  return (
    <BaseComponent className={className} primaryClassName="text-normal">
      {children}
    </BaseComponent>
  )
}

export function TextHandwritten({children, style, className}) {
  return (
    <BaseComponent className={className} primaryClassName="text-normal text-handwritten">
      {children}
    </BaseComponent>
  )
}
function TitleFont({children, style, className}) {
  return (
    <BaseComponent className={className} primaryClassName="text-title">
      {children}
    </BaseComponent>
  )
}


export function TextNav({children, style, className}) {
  return (
    <p className='navbar-text'>
      {children}
    </p>
  )
}

export default TitleFont;